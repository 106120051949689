.breadcrumb-item {
  color: white !important;
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-transform: capitalize;
}

.breadcrumb-item:hover {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
