.card-signup {
  width: 100%;
}

.card-signup input[type='checkbox'] {
  margin-right: 0.5rem;
}

.card-signup .checkbox-isento {
  width: auto !important;
}

.signup-section {
  display: none;
}

.signup-section.section-visible {
  display: block;
}

input.invalid,
select.invalid,
div.invalid,
textarea.invalid {
  outline: none;
  border: 1px solid red;
  box-shadow: 0px 0px 5px red;
  border-radius: 4px;
}

.btn-save-signup {
  margin-left: 0.5rem;
}

.card {
  position: static !important;
}
