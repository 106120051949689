.status-icon {
  border-radius: 100% !important;
  padding: 0px !important;

  width: 5.375rem;
  height: 5.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--light);
  font-size: 2.756rem;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
}
