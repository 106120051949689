.progess-container {
  position: relative;
}

.progess-container .progress-bar-label {
  position: absolute;
  top: -1px;
  text-align: center;

  font-weight: 500;
  font-size: 0.725rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
