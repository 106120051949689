/* Estilos de alinhamento */
.text-right {
  text-align: right;
}

.va {
  vertical-align: middle;
}

/* Espaçamento */
.margin {
  margin-top: 10px;
}

/*Estilo de título */
h1 {
  font-weight: bold !important;
  font-size: 35px !important;
}

h2 {
  font-weight: bold !important;
  font-size: 30px !important;
}

h3 {
  font-size: 20px !important;
  font-weight: bold !important;
}

h4 {
  font-size: 20px !important;
}

/* Estilos de cartão */
.Card {
  width: 100%;
  height: 50px;
}

/*Entre linhas formulário*/

.margin-form {
  margin-top: 10px;
}

.margin-form2x {
  margin-top: 20px;
}

/* Checkbox*/
.checkbox label {
  display: flex;
  align-items: center;
}

.checkbox input[type='checkbox'] {
  margin-right: 10px;
}

/* Estilos para os botões dos dias da semana */
.DayButton {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
}

/* Botões */

.button-selection {
  margin: 10px 10px 10px 0px;
  text-align: center;
  font-size: 18px;
  color: white;
  border-radius: 7px;
  width: 90px;
  height: 40px;
}

.button-confirm {
  text-align: center;
  border-radius: 10px;
  margin: 20px auto;
  width: auto;
  min-width: 280px;
  max-width: 1050px;
  height: 40px;
  display: block;
}

/*remove marcador lista */
li {
  list-style: none;
}

/* Estilos para botões etapas */
.button-circle,
.button-circleRed,
.button-circleAtual {
  margin: 5px;
  margin-right: 15px;
  padding: 10px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  position: relative;
  border-width: 0px;
}

/* Estilos de botões etapas com diferentes cores */
.button-circle {
  background-color: rgba(0, 189, 176, 0.82);
}

.button-circleRed {
  background-color: rgba(189, 0, 0, 0.82);
}

.button-circleAtual {
  background-color: rgba(26, 23, 23, 0.82);
}

/* Efeito de foco para botões circulares */
.button-circle:focus {
  background-color: #04867e;
}

.button-circleRed:focus {
  background-color: rgb(107, 5, 5);
}

/* Estilo para texto selecionado */
.selected {
  font-size: 5px;
}

/* Estilos para conteiner de etapas */
.botoes {
  text-align: center;
  flex-wrap: wrap;
}

/* Estilo para mensagens de erro */
.error {
  color: red;
}
